import { template as template_f5e9c39a7c544f62925fdf0455f4b680 } from "@ember/template-compiler";
const WelcomeHeader = template_f5e9c39a7c544f62925fdf0455f4b680(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
