import { template as template_ff9ea51a31764faf85fcff858976dd5e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ff9ea51a31764faf85fcff858976dd5e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
