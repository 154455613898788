import { template as template_be210343052b45d4969a013e02ce525e } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_be210343052b45d4969a013e02ce525e(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
