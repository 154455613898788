import { template as template_84a954930b5e43c18c079c95208906f4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_84a954930b5e43c18c079c95208906f4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
