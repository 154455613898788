import { template as template_eb78c7a91ef74ed7a1adcb8396e31d7c } from "@ember/template-compiler";
const FKText = template_eb78c7a91ef74ed7a1adcb8396e31d7c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
